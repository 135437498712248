import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import mondaySdk from "monday-sdk-js";
import axios from "axios";
import { Search } from "monday-ui-react-core";
import Spinner from "../Spinner";
import WorkspacesAndBoardsList from "./WorkspacesAndBoardsList";
import { Checkbox } from "monday-ui-react-core";
import useUser from "../../hooks/context/userHook";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import { useList } from "../../hooks/componentHooks/listHooks";
import useTokenHook from "../../hooks/context/useTokenHook";
import { useCheckSubitems } from "../../hooks/api/useCheckSubitems";
import { useCheckUpdates } from "../../hooks/api/useCheckUpdates";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const uri = process.env.REACT_APP_URI;

const AddItem = ({ calcHeight }) => {
  const { userId, accountId } = useUser();
  const { token } = useTokenHook();
  const {
    isLoading: itemsLoader,
    setItemLoading,
    setItemDisabled,
    isDisabled: itemsDisabled,
  } = useList();
  const { currentBoard, itemIds } = useBoardAndItems();
  const [newItemLoadingState] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [previousHeight, setPreviousHeight] = useState(0);
  const smallAccountContainer = useRef(null);
  const { subitemsExist, processSubitems, setProcessSubitems } =
    useCheckSubitems(itemIds);
  const { updatesExist, processUpdates, setProcessUpdates } =
    useCheckUpdates(itemIds);

  useEffect(() => {
    calcHeight(smallAccountContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkHeightChange = () => {
      if (smallAccountContainer.current) {
        const currentHeight = smallAccountContainer.current.offsetHeight;

        if (smallAccountContainer !== currentHeight) {
          // Height has changed

          setPreviousHeight(currentHeight);

          // Call calcHeight if needed
          calcHeight(smallAccountContainer);
        }
      }
    };

    // Initial check on mount
    checkHeightChange();

    // Set up a MutationObserver to listen for DOM changes
    const observer = new MutationObserver(checkHeightChange);
    if (smallAccountContainer.current) {
      observer.observe(smallAccountContainer.current, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });
    }

    // Clean up on unmount
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousHeight]);

  //this will create a new update and
  const addNewItem = async (toSyncGroupId, toSyncBoardId) => {
    try {
      //add the loading spinner

      setItemLoading(`${toSyncGroupId}-${toSyncBoardId}`);
      setItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      // TODO: Must be removed and checked on the actual endpoint
      const statusResponse = await axios.post(
        `${uri}/check-paywall-status`,
        { itemid: itemIds, targetBoardId: toSyncBoardId },
        {
          headers: {
            userid: userId,
          },
        }
      );

      if (!statusResponse.data.data.status.status) {
        monday.execute("notice", {
          message: statusResponse.data.data.status.message,
          type: "error",
          timeout: 10000,
        });
        return;
      }
      //end of paywall block

      //add-subItem endpoint
      await axios.post(
        `${uri}/simb/add-item-and-sync`,
        {
          payload: {
            accountId: accountId,
            itemIdArray: itemIds,
            originBoardId: currentBoard,
            target: {
              boardId: toSyncBoardId,
              groupId: toSyncGroupId,
            },
            processSubItem: processSubitems,
            processUpdates: processUpdates,
          },
        },
        {
          headers: {
            userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if (!addItem.data.error) {
      //   monday.execute("notice", {
      //     message: `Syncing Processing`,
      //     type: "info", // or "error" (red), or "info" (blue)
      //     timeout: 10000,
      //   });

      // }
    } catch (error) {
      console.log("error => ", error.message);

      // let errorMessage = `An error has occurred. Item did not sync successfully.`;

      // if (error?.response?.data?.error) {
      //   errorMessage = error?.response?.data?.error;

      //   if (errorMessage === "User unauthorized to perform action") {
      //     errorMessage =
      //       "You do not have permissions to create or edit in this board.";
      //   }
      // }

      // removeItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      // monday.execute("notice", {
      //   message: errorMessage,
      //   type: "error", // or "error" (red), or "info" (blue)
      //   timeout: 10000,
      // });
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    // Perform search on the initial workspaces
  };

  return (
    <div className="relative" ref={smallAccountContainer}>
      <Row className="pt-[15px] pb-[12px] bg-[#FFFFFF] sticky top-0 z-[1] border-b border-[#C3C6D4] overflow-x-hidden">
        {/* Dropdown Search */}
        <>
          <Search
            placeholder="Search Workspaces and Boards"
            onChange={(e) => {
              handleSearch(e);
            }}
            size={Search.sizes.SMALL}
            style={{
              width: "100%",
            }}
            value={searchText}
          />

          <div className="flex flex-col gap-2 mt-2">
            {subitemsExist && (
              <Checkbox
                label="Include Subitems"
                checked={processSubitems}
                onChange={(e) => {
                  setProcessSubitems(e.target.checked);
                }}
              />
            )}
            {updatesExist && (
              <Checkbox
                label="Include Updates"
                checked={processUpdates}
                onChange={(e) => {
                  setProcessUpdates(e.target.checked);
                }}
              />
            )}
          </div>
        </>
      </Row>

      <Row
        style={{
          backgroundColor: "#FFFFFF",
          width: "100%",
        }}
        className="rounded-b add-item-row"
      >
        <Col span={24} className="pb-0 mt-2 mb-0">
          {!newItemLoadingState ? (
            <>
              <WorkspacesAndBoardsList
                searchText={searchText}
                addItem={addNewItem}
                itemsLoader={itemsLoader}
                itemsDisabled={itemsDisabled}
              />
            </>
          ) : (
            <>
              <div className="flex flex-col items-center gap-2">
                <Spinner className="mb-2" />
                <span>
                  Adding your item to the new board. Please do not close this
                  window and the item view.
                </span>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AddItem;
