import React from "react";

import { Col, Row, Divider } from "antd";

import EmptyLocations from "./EmptyLocations";
import "monday-ui-react-core/dist/main.css";

import ErrorPage from "../errorPage";
import ItemsOnBoardTable from "./ItemsOnBoardTable";
import SharedSubitemSettings from "./SharedSubitemSettings";
import useItemLocations from "../../hooks/api/useItemLocations";
import PageLoader from "../PageLoader";
import Navbar from "../Navbar/Navbar";
import AddItemButton from "../addItem/AddItemButton";
import { useErrorWebsocket } from "../../hooks/useErrorWebsocketService";
import useUser from "../../hooks/context/userHook";

const ItemsOnBoard = ({ authorized }) => {
  const { userId } = useUser();
  const { isLoading, error, tableData, itemIds, mutateItemLocations } =
    useItemLocations();

  useErrorWebsocket(userId ? userId.toString() : undefined);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorPage revalidate={mutateItemLocations} />;
  }

  return (
    <>
      {tableData.length === 0 ? (
        <EmptyLocations />
      ) : (
        <>
          <Navbar />
          <Row
            style={{
              backgroundColor: "#ffffff",
            }}
            className="pb-4"
          >
            <Col
              span={24}
              className="flex items-center justify-between pb-0 mt-1"
            >
              {/* Page Title & Share Subitems Toggle */}
              <div className="flex flex-col">
                <div className="grid grid-cols-1 gap-2">
                  <h3 className="font-semibold">Item Locations</h3>
                  {itemIds.length === 1 && <SharedSubitemSettings />}
                </div>
              </div>

              {/* New Board Button */}
              <div className="flex flex-col items-end gap-2">
                <AddItemButton centered={false} />
              </div>
            </Col>

            <div className="flex items-center mt-2 text-sm text-gray-500 text-slight">
              To synchronize columns, ensure they have the same column name.
            </div>

            <Divider />

            <div className="flex flex-col flex-wrap w-full gap-y-2 gap-x-4">
              <ItemsOnBoardTable tableData={tableData} />
            </div>

            <Divider className="!mt-4 !mb-0" />
          </Row>
        </>
      )}
    </>
  );
};

export default ItemsOnBoard;
